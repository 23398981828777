import React, { FC } from 'react';
import Careers from './Careers/Careers';
import { Container } from './FrontpageCareersStyle';
import People from './People/People';
import { CareersType, PeopleType } from '../../interfaces/frontpage';

interface FrontpageCareersProps {
    headline: string;
    careers: CareersType;
    people: PeopleType;
}

const FrontpageCareers: FC<FrontpageCareersProps> = ({ headline, careers, people }) => (
    <Container>
        <Careers
            headline={headline}
            description={careers?.description}
            link={careers?.link}
            link_label={careers?.link_label}
            title={careers?.title}
        />
        <People people={people} />
    </Container>
);

export default FrontpageCareers;

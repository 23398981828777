import React, { useEffect } from 'react';
import Layout from '../components/Layout/Layout';
import { graphql } from 'gatsby';
import LandingPage from '../components/LandingPage/LandingPage';
import { useI18next } from 'gatsby-plugin-react-i18next';
import AboutUs from '../components/AboutUs/AboutUs';
import OurSolutions from '../components/OurSolutions/OurSolutions';
import OurProducts from '../components/OurProducts/OurProducts';
import OurTeam from '../components/OurTeam/OurTeam';
import FrontpageNews from '../components/FrontpageNews/FrontpageNews';
import { FieldBannerInterface } from '../interfaces/frontpage';
import { fieldBannerExample } from '../templateObjects/frontPageObjects';
import AutoPlaySliderWithDots from '../components/Sliders/AutoPlaySliderWithDots/AutoPlaySliderWithDots';
import FrontpageCareers from '../components/FrontpageCareers/FrontpageCareers';
import HelpPhone from '../components/HelpPhone/HelpPhone';
import { DE, EN, GLOBAL, NL_NL } from '../constants/languageCode';
import Alternate from '../components/Seo/Alternate';

const IndexPage = ({
    data: {
        drupal: { frontpage: frontpage },
    },
}) => {
    const { language, changeLanguage } = useI18next();
    const field_about_us = frontpage?.field_about_us ?? {};
    const field_our_solution = frontpage?.field_our_solution ?? {};
    const field_our_products = frontpage?.field_our_products ?? {};
    const field_product_redirection = frontpage?.field_product_redirection ?? {};
    const field_our_highlights = frontpage?.field_our_highlights ?? {};
    const field_our_team = frontpage?.field_our_team ?? {};
    const field_show_teams = frontpage?.field_show_teams;
    const field_all_events = frontpage?.field_all_events ?? {};
    const field_boge_updates = frontpage?.field_boge_updates ?? {};
    const frontpage_events = frontpage?.frontpage_events ?? {};
    const frontpage_news = frontpage?.frontpage_news ?? {};
    const field_banner: FieldBannerInterface[] = frontpage?.field_banner ?? fieldBannerExample;
    const field_show_careers = frontpage?.field_show_careers;
    const field_careers = frontpage?.field_careers ?? {};
    const field_people_at_boge = frontpage?.field_people_at_boge ?? {};
    const field_about_us_section_title = frontpage?.field_about_us_section_title ?? '';
    const field_our_careers_section_title = frontpage?.field_our_careers_section_title ?? '';
    const field_our_team_section_title = frontpage?.field_our_team_section_title ?? '';
    const field_our_products_section_title = frontpage?.field_our_products_section_title ?? '';
    const field_our_solution_section_title = frontpage?.field_our_solution_section_title ?? '';
    const field_about_us_image = frontpage?.field_about_us_image ?? '';
    const news_title = frontpage?.field_news_section_title ?? '';
    const events_title = frontpage?.field_events_section_title ?? '';
    const help_phone_languages = [EN, DE, NL_NL];
    const languagesWithUrls = [];
    process.env.LANGUAGES.split(',').forEach((language: string) =>
        languagesWithUrls.push({ language: language, url: '' })
    );

    useEffect(() => {
        if (typeof window !== 'undefined' && window.location.pathname === '/') {
            changeLanguage(GLOBAL);
        }
    }, []);

    const displayContent = (language) => {
        if (language === process.env.DEFAULT_LANGUAGE) {
            return (
                <>
                    <Alternate languages={languagesWithUrls} />
                    <LandingPage />
                </>
            );
        } else {
            return (
                <Layout
                    title={frontpage?.metatag?.field_metatag_title}
                    description={frontpage?.metatag?.field_metatag_description}
                    noIndex={frontpage?.metatag?.field_metatag_no_index}
                >
                    <Alternate languages={languagesWithUrls} />
                    {help_phone_languages.includes(language) && <HelpPhone />}
                    {field_banner.length > 0 && field_banner[0].field_media_image?.length > 0 && (
                        <AutoPlaySliderWithDots sliderData={field_banner} />
                    )}
                    {(Object.keys(frontpage_events).length > 0 || Object.keys(frontpage_news).length > 0) && (
                        <FrontpageNews
                            news={frontpage_news}
                            events={frontpage_events}
                            allNewsLink={field_boge_updates}
                            allEventsLink={field_all_events}
                            newsTitle={news_title}
                            eventsTitle={events_title}
                        />
                    )}
                    {Object.keys(field_about_us).length !== 0 &&
                        (field_about_us?.title ||
                            field_about_us?.description ||
                            field_about_us?.link ||
                            field_about_us?.link_label) && (
                            <AboutUs
                                headline={field_about_us_section_title}
                                title={field_about_us.hasOwnProperty('title') ? field_about_us.title : ''}
                                description={
                                    field_about_us.hasOwnProperty('description') ? field_about_us.description : ''
                                }
                                link={field_about_us.hasOwnProperty('link') ? field_about_us.link : ''}
                                linkLabel={field_about_us.hasOwnProperty('link_label') ? field_about_us.link_label : ''}
                                image={field_about_us_image}
                            />
                        )}
                    {field_show_teams && <OurTeam headline={field_our_team_section_title} team={field_our_team} />}
                    {(Object.keys(field_our_products).length !== 0 ||
                        Object.keys(field_product_redirection).length !== 0 ||
                        Object.keys(field_our_highlights).length !== 0) && (
                        <OurProducts
                            headline={field_our_products_section_title}
                            products={field_our_products}
                            productRedirection={field_product_redirection}
                            ourHighlights={field_our_highlights}
                        />
                    )}
                    {Object.keys(field_our_solution).length !== 0 &&
                        Object.values(field_our_solution[0]).some((item) => item !== null) && (
                            <OurSolutions
                                headline={field_our_solution_section_title}
                                title={
                                    field_our_solution[0]?.field_title?.length > 0
                                        ? field_our_solution[0]?.field_title
                                        : ''
                                }
                                description={
                                    field_our_solution[0]?.field_description?.length > 0
                                        ? field_our_solution[0]?.field_description
                                        : ''
                                }
                                link={field_our_solution[0]?.field_link ?? null}
                                imgSrc={
                                    field_our_solution[0]?.field_image?.length > 0
                                        ? field_our_solution[0]?.field_image[0]?.field_media_image
                                        : ''
                                }
                                imgAlt={
                                    field_our_solution[0]?.field_image?.length > 0
                                        ? field_our_solution[0]?.field_image[0]?.alt
                                        : ''
                                }
                            />
                        )}
                    {field_show_careers && (
                        <FrontpageCareers
                            headline={field_our_careers_section_title}
                            careers={field_careers}
                            people={field_people_at_boge}
                        />
                    )}
                </Layout>
            );
        }
    };
    return displayContent(language);
};

export default IndexPage;

export const query = graphql`
    query ($language: String!) {
        drupal {
            frontpage(language: $language) {
                metatag {
                    field_metatag_description
                    field_metatag_title
                    field_metatag_no_index
                }
                id
                field_banner {
                    alt
                    field_banner_link {
                        uri
                        text
                    }
                    field_media_image
                    field_text
                }
                field_about_us_section_title
                field_about_us {
                    description
                    link
                    link_label
                    title
                }
                field_about_us_image {
                    alt
                    field_media_image
                    title
                }
                field_our_products_section_title
                field_our_products {
                    field_description
                    field_image {
                        alt
                        field_media_image
                        field_type
                        title
                    }
                    field_link {
                        text
                        uri
                    }
                    field_title
                }
                field_our_highlights {
                    field_description
                    field_image {
                        alt
                        field_media_image
                    }
                    field_link {
                        uri
                        text
                    }
                    field_title
                }
                field_product_redirection {
                    description
                    link
                    link_label
                    title
                }
                field_our_solution_section_title
                field_our_solution {
                    field_description
                    field_link {
                        uri
                        text
                    }
                    field_title
                    field_image {
                        alt
                        field_media_image
                    }
                }
                field_show_teams
                field_our_team_section_title
                field_our_team {
                    field_link {
                        text
                        uri
                    }
                    field_persons {
                        field_email
                        field_image {
                            alt
                            field_media_image
                        }
                        field_phone
                        field_mobile
                        title
                        field_position
                    }
                    field_title
                }
                field_all_events {
                    text
                    uri
                }
                field_boge_updates {
                    text
                    uri
                }
                frontpage_events {
                    title
                    field_location
                    field_event_date {
                        start_date
                        end_date
                    }
                    field_event_link {
                        text
                        uri
                    }
                }
                frontpage_news {
                    url
                    title
                    field_image {
                        alt
                        field_media_image
                    }
                }
                field_our_careers_section_title
                field_show_careers
                field_careers {
                    description
                    link
                    link_label
                    title
                }
                field_people_at_boge {
                    field_link {
                        text
                        uri
                    }
                    field_persons {
                        field_bio
                        field_image {
                            alt
                            field_media_image
                        }
                    }
                }
                field_news_section_title
                field_events_section_title
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

import styled from 'styled-components';
import { ContainerColumnStart } from '../../styles/Common';
import { deviceMax } from '../../styles/MediaQuery';
import { containerWidth } from '../../styles/Variables';

export const Container = styled(ContainerColumnStart)`
    margin: 0 auto;
    margin-bottom: 3rem;

    @media ${deviceMax.laptop} {
        padding: 0 10px;
    }
`;

export const ProductsContainer = styled.div`
    width: ${containerWidth};
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: 220px 220px;
    grid-gap: 20px;
    margin-bottom: 20px;

    a:first-child {
        grid-area: 1 / 1 / 3 / 2;
    }

    @media ${deviceMax.laptop} {
        width: 100%;
        grid-template-columns: 1fr;
        grid-template-rows: 220px;
    }
`;

export const RedirectionHighlightsContainer = styled.div`
    width: ${containerWidth};
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 220px;
    grid-gap: 20px;
    margin-bottom: 20px;

    @media ${deviceMax.laptop} {
        width: 100%;
        grid-template-columns: 1fr;
    }
`;

import styled from 'styled-components';
import { ContainerColumnWithoutWidth, SectionHeadline } from '../../../styles/Common';
import { darkGreen, greyLight } from '../../../styles/Variables';

export const MemberWrapper = styled(ContainerColumnWithoutWidth)`
    justify-content: center;
    width: 100%;
`;

export const Title = styled(SectionHeadline)`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${greyLight};
    width: 100%;
    min-height: 3rem;
    margin-bottom: 0;
    padding: 0;
`;

export const ContactData = styled.div`
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
`;

export const MemberImage = styled.img`
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: opacity 0.3s;
`;

export const ContentContainer = styled(ContainerColumnWithoutWidth)`
    justify-content: center;
    position: relative;
    width: 100%;
    min-height: calc(100% - 3rem);

    &:hover {
        ${ContactData} {
            opacity: 1;
        }
        ${MemberImage} {
            opacity: 0.3;
        }
    }
`;

export const ContactDataText = styled.p`
    font-size: 0.8rem;
    padding: 0;
    margin: 0;
`;

export const ContactDataLink = styled.a`
    text-decoration: none;
    font-size: 0.8rem;
    color: ${darkGreen};
    padding: 0;
    margin: 0;
`;

import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { FC } from 'react';
import { SectionHeadline } from '../../../styles/Common';
import SinglePhotoWithTextSlider from '../../Sliders/SinglePhotoWithTextSlider/SinglePhotoWithTextSlider';
import { Container } from './PeopleStyle';
import { PeopleType } from '../../../interfaces/frontpage';

interface PeopleProps {
    people: PeopleType;
}

const People: FC<PeopleProps> = ({ people }) => {
    const { t } = useTranslation();

    return (
        <Container>
            <SectionHeadline>{t('peopleAtBoge')}</SectionHeadline>
            <SinglePhotoWithTextSlider items={people} />
        </Container>
    );
};

export default People;

import { DE, EN } from '../../../constants/languageCode';

export const careersPages = (language: string) => {
    switch (language) {
        case DE:
            return [
                {
                    name: 'Praktika & Werkstudenten',
                    url: 'praktika-werkstudenten',
                },
                {
                    name: 'Ausbildung & Studium',
                    url: 'ausbildung-studium',
                },
            ];
        case EN:
            return [
                {
                    name: 'Traineeships and working students',
                    url: 'traineeships-working-students',
                },
                {
                    name: 'Career Entrants',
                    url: 'current-vacancies',
                },
                {
                    name: 'Experienced Professionals',
                    url: 'current-vacancies',
                },
                {
                    name: 'Training and study',
                    url: 'current-vacancies',
                },
            ];
        default:
            return [];
    }
};

import React, { useState, useEffect } from 'react';
import GlobalStyles from '../../styles/GlobalStyles';
import Typography from '../../styles/Typography';
import { pageAddress } from '../../utils/pageAddress';
import {
    ArrowBtn,
    ContactData,
    ContactDataItem,
    Container,
    ContentContainer,
    Footer,
    FooterContent,
    FooterText,
    FormContainer,
    Header,
    RedirectContainer,
} from './LandingPageStyle';
import { Link } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import { Helmet } from 'react-helmet';
import Seo from '../Seo/Seo';
import RibbonUnsupportedBrowser from '../RibbonUnsupportedBrowser/RibbonUnsupportedBrowser';
import { EN } from '../../constants/languageCode';

const LandingPage = () => {
    const PAGE_TITLE = 'BOGE | Kompressoren | Druckluft ';
    const PAGE_DESC =
        'BOGE - Hersteller von Kompressoren für Druckluft aus Deutschland. Jetzt informieren und unverbindlich beraten lassen.';

    const defaultCountry = {
        country: 'International',
        site: 'global',
    };
    const [siteAddress, setSiteAddress] = useState(EN);
    const listOfCountries = pageAddress.map((country) =>
        country.country === defaultCountry.country ? defaultCountry : country
    );
    const charArr = [...new Set(listOfCountries.map((item: any) => item.country.slice(0, 1)))];
    const { changeLanguage } = useI18next();

    const redirectOnSubmit = (e: any) => {
        e.preventDefault();
        changeLanguage(siteAddress);
    };

    useEffect(() => {
        if (typeof document !== 'undefined') {
            const head = document.querySelector('head');
            const meta = document.createElement('meta');
            meta.setAttribute('data-privacy-proxy-server', process.env.PRIVACY_PROXY_META);
            head.appendChild(meta);
        }
    }, []);

    return (
        <>
            <GlobalStyles />
            <Seo title={PAGE_TITLE} description={PAGE_DESC} />
            <Helmet>
                <script
                    id="usercentrics-cmp"
                    src={process.env.COOKIES_LINK}
                    data-settings-id={process.env.COOKIES_ID}
                    async
                />
                <script
                    id="usercentrics-privacy-proxy"
                    type="application/javascript"
                    src={process.env.PRIVACY_PROXY_SCRIPT}
                />
                <script id="gtm-usercentrics-consent-mode" type="text/javascript">
                    {`
                        window.dataLayer = window.dataLayer || []; 
                        function gtag() { 
                            dataLayer.push(arguments); 
                        } 
                        
                        gtag("consent", "default", {
                             ad_user_data: "denied", 
                             ad_personalization: "denied", 
                             ad_storage: "denied", 
                             analytics_storage: "denied", 
                             wait_for_update: 2000
                        });
                        
                        gtag("set", "ads_data_redaction", true);
                    `}
                </script>
                <script id="gtm-init" type="text/javascript">
                    {`
                        (function(w, d, s, l, i) {
                            w[l] = w[l] || [];
                            w[l].push({
                                'gtm.start': new Date().getTime(),
                                event: 'gtm.js'
                            });
                            var f = d.getElementsByTagName(s)[0],
                                j = d.createElement(s),
                                dl = l != 'dataLayer' ? '&l=' + l : '';
                            j.async = true;
                            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                            f.parentNode.insertBefore(j, f);
                        })(window, document, 'script', 'dataLayer', '${process.env.GTM_CODE}');
                    `}
                </script>
                <script id="virtual-pageview-tracking" type="text/javascript">
                    {`
                        window.dataLayer = window.dataLayer || [];
                        dataLayer.push({
                            'event':'VirtualPageview',
                            'virtualPageURL':'${typeof window !== 'undefined' && window.location.pathname}',
                            'virtualPageTitle':'${PAGE_TITLE}'
                        });
                    `}
                </script>
            </Helmet>
            <Typography />
            <Container>
                <Header>
                    <StaticImage src="../../static/images/boge-logo.png" alt="logo" width={200} placeholder={'none'} />
                </Header>
                <RibbonUnsupportedBrowser />
                <ScrollToTop />
                <RedirectContainer>
                    <p>Welcome to BOGE compressors worldwide</p>
                    <FormContainer>
                        <form onSubmit={redirectOnSubmit}>
                            <select
                                onChange={(e) =>
                                    setSiteAddress(e.target.value === defaultCountry.site ? EN : e.target.value)
                                }
                                defaultValue={defaultCountry.site}
                            >
                                {listOfCountries.map((item: any) => (
                                    <option key={item.country} value={item.site}>
                                        {item.country}
                                    </option>
                                ))}
                            </select>
                            <button type="submit">
                                <ArrowBtn />
                            </button>
                        </form>
                    </FormContainer>
                </RedirectContainer>
                <ContentContainer>
                    <div>
                        <h2>BOGE compressed air systems</h2>
                        <p>
                            is a manufacturer of industrial compressors and compressed air systems. The product range
                            includes oil-free and oil-lubricated screw and piston compressors, scroll and turbo
                            compressors, custom-tailored solutions and everything around compressed air treatment.
                        </p>
                    </div>
                    <div>
                        <h2>
                            Switch to the website of our daughter companies, sales offices or visit BOGE international.
                        </h2>
                        <div>
                            {charArr.map((item: any, index) => (
                                <div key={index}>
                                    <h3>{item}</h3>
                                    {listOfCountries.map((el: any, index) => {
                                        if (el.country.slice(0, 1) == item) {
                                            return (
                                                <div key={index}>
                                                    <span
                                                        onClick={() =>
                                                            changeLanguage(
                                                                el.site === defaultCountry.site ? EN : el.site
                                                            )
                                                        }
                                                    >
                                                        {el.country}
                                                    </span>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            ))}
                        </div>
                    </div>
                </ContentContainer>
                <Footer>
                    <FooterContent>
                        <h4>General Contact</h4>
                        <ContactData>
                            <ContactDataItem>
                                <p>BOGE Kompressoren</p>
                                <p>Otto Boge GmbH & Co KG</p>
                                <p>Otto-Boge-Straße 1-7</p>
                                <p>D-33739 Bielefeld</p>
                            </ContactDataItem>
                            <ContactDataItem>
                                <p>
                                    Tel: <a href="tel:+49 5206 601-0">+49 5206 601-0</a>
                                </p>
                                <p>
                                    Fax: <a href="tel:+49 5206 601-200">+49 5206 601-200</a>
                                </p>
                                <p>
                                    Email: <a href="mailto:info@boge.com">info@boge.com</a>
                                </p>
                            </ContactDataItem>
                        </ContactData>
                        <h2>
                            &copy; {new Date().getFullYear()} <Link to="/en/legal">BOGE | LEGAL</Link>
                        </h2>
                    </FooterContent>
                    <FooterText>
                        <span>Boge air. The air to work.</span>
                    </FooterText>
                </Footer>
            </Container>
        </>
    );
};

export default LandingPage;

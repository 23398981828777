import { navigate } from 'gatsby';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { FC } from 'react';
import { SectionHeadline, SectionTitle } from '../../../styles/Common';
import { careersPages } from './careersPages';
import { CareersText, SectionContainer, JobsButton, Select } from './CareersStyle';
import { CareersType } from '../../../interfaces/frontpage';
import { linkHelper } from '../../../utils/linkHelper';
import { DE, EN } from '../../../constants/languageCode';

const Careers: FC<CareersType> = ({ headline, description, link, link_label, title }) => {
    const { t } = useTranslation();
    const { language } = useI18next();
    const languages = [DE, EN];
    const careersPageAddresses = careersPages(language);

    return (
        <SectionContainer>
            <SectionHeadline>{headline !== '' ? headline : t('careersSectionHeadline')}</SectionHeadline>
            <SectionTitle>{title}</SectionTitle>
            <CareersText>{description}</CareersText>
            {link && link_label && <JobsButton to={linkHelper(link, language)}>{link_label}</JobsButton>}
            {languages.indexOf(language) !== -1 && (
                <>
                    <CareersText>{t('joiningBoge')}</CareersText>
                    <Select onChange={(e) => navigate(e.target.value)}>
                        <option value={language}>{t('choosePage')}</option>
                        {careersPageAddresses.map((item: any) => (
                            <option key={item.name} value={item.url}>
                                {item.name}
                            </option>
                        ))}
                    </Select>
                </>
            )}
        </SectionContainer>
    );
};

export default Careers;

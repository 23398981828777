import React, { FC, ReactNode, useState } from 'react';
import {
    ContentWrapper,
    Description,
    PageLink,
    SlideImage,
    SliderWrapper,
    TextWrapper,
    ThumbnailImage,
    ThumbnailsWrapper,
} from './SinglePhotoWithTextSliderStyle';
import placeHolder from '../../../static/images/placeholder.jpg';
import { urlForImage } from '../../../utils/urlForImage';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { linkHelper } from '../../../utils/linkHelper';

interface SinglePhotoWithTextSliderProps {
    items: Object;
}

const SinglePhotoWithTextSlider: FC<SinglePhotoWithTextSliderProps> = ({ items }) => {
    const { language } = useI18next();
    const [activeSlide, setActiveSlide] = useState(0);
    const persons = items[0]?.field_persons ?? [];
    const field_link = items[0]?.field_link ?? {};

    const displayThumbnails = (): ReactNode => (
        <ThumbnailsWrapper>
            {persons
                ?.filter((item: any, index: number) => index < 4 && item)
                ?.map((item: any, index: number) => (
                    <ThumbnailImage
                        key={index}
                        isActive={index === activeSlide}
                        image={
                            item?.field_image?.length > 0
                                ? urlForImage(item?.field_image[0]?.field_media_image)
                                : placeHolder
                        }
                        onClick={() => setActiveSlide(index)}
                    />
                ))}
        </ThumbnailsWrapper>
    );

    const displaySlide = (): ReactNode => {
        return persons
            ?.filter((item: any, index: number) => index < 4 && item)
            ?.map((item: any, index: number) => (
                <ContentWrapper key={index} isActive={index === activeSlide} position={index < activeSlide}>
                    <SlideImage
                        isActive={index === activeSlide}
                        src={
                            item?.field_image?.length > 0
                                ? urlForImage(item.field_image[0]?.field_media_image)
                                : placeHolder
                        }
                        alt={item?.field_image?.length > 0 ? item?.field_image[0]?.alt : ''}
                    />
                    <TextWrapper isActive={index === activeSlide}>
                        <Description
                            isActive={index === activeSlide}
                            dangerouslySetInnerHTML={{ __html: item?.field_bio }}
                        ></Description>
                        <PageLink
                            to={
                                field_link?.uri?.length > 0
                                    ? linkHelper(field_link.uri?.replace('internal:/', ''), language)
                                    : '#'
                            }
                        >
                            {field_link?.text?.length > 0 && field_link.text}
                        </PageLink>
                    </TextWrapper>
                </ContentWrapper>
            ));
    };

    return (
        <SliderWrapper>
            {persons.length > 0 && displaySlide()}
            {persons.length > 0 && displayThumbnails()}
        </SliderWrapper>
    );
};

export default SinglePhotoWithTextSlider;

import React from 'react';
import { Container, PhoneIcon, PhoneText, PhoneNumber } from './HelpPhoneStyle';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const HelpPhone = () => {
    const { t } = useTranslation();

    return (
        <Container>
            <PhoneIcon />
            <PhoneText>{t('canWeHelpYou')}</PhoneText>
            <PhoneNumber href={`tel:${t('helpLine')}`}>{t('helpLine')}</PhoneNumber>
        </Container>
    );
};

export default HelpPhone;

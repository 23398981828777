import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { FC } from 'react';
import {
    ContactData,
    ContactDataLink,
    ContactDataText,
    ContentContainer,
    MemberImage,
    MemberWrapper,
    Title,
} from './TeamMemberStyle';
import placeHolder from '../../../static/images/placeholder.jpg';
import { urlForImage } from '../../../utils/urlForImage';

export interface TeamMemberProps {
    name: string | null;
    imgSrc: string | null;
    imgAlt: string | null;
    mobile: string | null;
    email: string | null;
    phone: string | null;
}

const TeamMember: FC<TeamMemberProps> = ({ name, imgSrc, imgAlt, mobile, email, phone }) => {
    const { t } = useTranslation();

    return (
        <MemberWrapper>
            <Title>{name}</Title>
            <ContentContainer>
                <MemberImage src={imgSrc ? urlForImage(imgSrc) : placeHolder} alt={imgAlt ? imgAlt : 'person img'} />
                <ContactData>
                    {mobile && (
                        <ContactDataText>
                            {t('mobile')}: <ContactDataLink href={`tel:${mobile}`}>{mobile}</ContactDataLink>
                        </ContactDataText>
                    )}
                    {phone && (
                        <ContactDataText>
                            {t('phone')}: <ContactDataLink href={`tel:${phone}`}>{phone}</ContactDataLink>
                        </ContactDataText>
                    )}
                    {email && <ContactDataLink href={`mailto:${email}`}>{email}</ContactDataLink>}
                </ContactData>
            </ContentContainer>
        </MemberWrapper>
    );
};

export default TeamMember;

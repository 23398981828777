import { FieldBannerInterface } from '../interfaces/frontpage';

export const fieldBannerExample: FieldBannerInterface[] = [
    {
        alt: null,
        field_banner_link: {
            uri: null,
            text: null,
        },
        field_media_image: null,
        field_text: null,
    },
];

import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { SectionHeadline, SectionTitle } from '../../styles/Common';
import { Container, RedirectButton, TeamSection } from './OurTeamStyle';
import TeamMember from './TeamMember/TeamMember';
import { linkHelper } from '../../utils/linkHelper';

const OurTeam = ({ headline, team: team }) => {
    const {
        t,
        i18n: { language },
    } = useTranslation();

    return (
        <Container>
            {team[0]?.field_title && (
                <>
                    <SectionHeadline>{headline !== '' ? headline : t('yourBogeContactPerson')}</SectionHeadline>
                    <SectionTitle>{team[0]?.field_title}</SectionTitle>
                </>
            )}
            <TeamSection>
                {team[0]?.field_persons
                    ?.filter((item: any, index: number) => index < 4 && item)
                    .map((item: any, index: number) => (
                        <TeamMember
                            key={index}
                            name={item?.field_position ?? item?.title}
                            imgSrc={item?.field_image?.length > 0 && item?.field_image[0]?.field_media_image}
                            imgAlt={item?.field_image?.length > 0 && item?.field_image[0]?.field_alt}
                            mobile={item?.field_mobile}
                            email={item?.field_email}
                            phone={item?.field_phone}
                        />
                    ))}
            </TeamSection>
            {team && team.length > 0 && team[0]?.field_link?.text && team[0]?.field_link?.uri && (
                <RedirectButton to={linkHelper(team[0].field_link.uri, language)}>
                    {team[0].field_link.text}
                </RedirectButton>
            )}
        </Container>
    );
};

export default OurTeam;

import styled from 'styled-components';
import { ContainerColumnWithoutWidth } from '../../../styles/Common';
import { deviceMax } from '../../../styles/MediaQuery';

export const Container = styled(ContainerColumnWithoutWidth)`
    align-items: flex-start;
    flex: 2;
    padding-left: 20px;

    @media ${deviceMax.tablet} {
        flex: unset;
        width: 100%;
        margin-top: 2rem;
        padding-left: 0;
    }
`;

import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { FC } from 'react';
import {
    AboutUsWrapper,
    Button,
    ContentContainer,
    DescriptionContainer,
    DescriptionText,
    DescriptionWrapper,
    Image,
    Title,
} from './AboutUsStyle';
import { linkHelper } from '../../utils/linkHelper';
import { SectionHeadline } from '../../styles/Common';
import { urlForImage } from '../../utils/urlForImage';

interface AboutUsProps {
    headline: string;
    title: string | null;
    description: string | null;
    link: string | null;
    linkLabel: string | null;
    image: {
        alt?: string;
        field_media_image: string;
    };
}

const AboutUs: FC<AboutUsProps> = ({ headline, title, description, link, linkLabel, image }) => {
    const {
        t,
        i18n: { language },
    } = useTranslation();

    return (
        <AboutUsWrapper>
            <ContentContainer>
                <SectionHeadline>{headline !== '' ? headline : t('aboutUs')}</SectionHeadline>
                <Title to={linkHelper(link ?? '#', language)}>{title}</Title>
            </ContentContainer>
            <DescriptionWrapper>
                <ContentContainer>
                    <DescriptionContainer>
                        {image?.field_media_image && (
                            <Image alt={image?.alt} src={urlForImage(image?.field_media_image)} />
                        )}
                        <DescriptionText>{description}</DescriptionText>
                    </DescriptionContainer>
                    <Button to={linkHelper(link ?? '#', language)}>{linkLabel}</Button>
                </ContentContainer>
            </DescriptionWrapper>
        </AboutUsWrapper>
    );
};

export default AboutUs;

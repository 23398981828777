import styled from 'styled-components';
import { deviceMax, deviceMin } from '../../styles/MediaQuery';
import { containerWidth } from '../../styles/Variables';

export const Container = styled.div`
    width: ${containerWidth};
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: 4rem;

    @media ${deviceMax.mobileS} {
        width: calc(100% - 20px);
        padding: 0 10px;
    }

    @media ${deviceMin.mobileS} {
        width: calc(100% - 20px);
        padding: 0 10px;
    }

    @media ${deviceMin.tablet} {
        width: calc(100% - 20px);
        padding: 0 10px;
    }

    @media ${deviceMin.tabletXl} {
        width: ${containerWidth};
    }
`;

import { Link } from 'gatsby';
import styled from 'styled-components';
import { SectionHeadline } from '../../../styles/Common';
import { darkGreen, greyLight } from '../../../styles/Variables';

export const ProductWrapper = styled(Link)`
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
    cursor: pointer;
`;

export const LinkTitle = styled(SectionHeadline)`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${greyLight};
    width: 100%;
    min-height: 4rem;
    margin-bottom: 0;
    padding: 0;

    &:hover {
        color: ${darkGreen};
    }
`;

export const ProductImage = styled.img`
    width: 100%;
    height: calc(100% - 4rem);
    object-fit: cover;
`;

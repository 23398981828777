import styled from 'styled-components';
import { ContainerColumnStart, LinkButton } from '../../styles/Common';
import { deviceMax } from '../../styles/MediaQuery';

export const Container = styled(ContainerColumnStart)`
    margin: 0 auto;
    margin-bottom: 3rem;

    @media ${deviceMax.laptop} {
        padding: 0 10px;
    }
`;

export const TeamSection = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;

    @media ${deviceMax.laptop} {
        grid-template-columns: repeat(4, 1fr);
    }

    @media ${deviceMax.tablet} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${deviceMax.mobileL} {
        grid-template-columns: 1fr;
    }
`;

export const RedirectButton = styled(LinkButton)``;

import styled, { css } from 'styled-components';
import { darkGreyText, greyBorder } from '../../../styles/Variables';
import { Link } from 'gatsby';
import { ContainerColumnWithoutWidth, LinkButton } from '../../../styles/Common';
import { deviceMax } from '../../../styles/MediaQuery';

const newsSectionCardSizes = {
    width: '215px',
    height: '115px',
};

interface SectionContainerProps {
    border?: boolean;
}

export const SectionContainer = styled(ContainerColumnWithoutWidth)<SectionContainerProps>`
    align-items: flex-start;

    ${({ border }) =>
        border &&
        css`
            padding-right: 20px;
            border-right: 1px solid ${greyBorder};
        `}

    @media ${deviceMax.tablet} {
        padding-right: 0;
        border: none;
        margin-bottom: 1rem;
    }
`;

export const NewsWrapper = styled.div`
    max-width: 100%;
    display: flex;

    @media ${deviceMax.mobileXl} {
        flex-direction: column;
    }
`;

export const NewsCard = styled(Link)`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 1.25rem;
    margin-top: 0.5rem;
    min-width: ${newsSectionCardSizes.width};

    @media ${deviceMax.laptop} {
        min-width: calc(${newsSectionCardSizes.width} * 0.8);
    }

    @media ${deviceMax.mobileXl} {
        margin-right: 0;
        margin-bottom: 2rem;
    }

    &:last-child {
        margin-right: 0;
    }
`;

export const NewsImage = styled.img`
    width: 100%;
    height: ${newsSectionCardSizes.height};
    object-fit: cover;

    @media ${deviceMax.mobileXl} {
        height: auto;
    }
`;

export const NewsDescription = styled.p`
    color: ${darkGreyText};
    font-weight: 200;
    font-size: 0.875rem;
    align-self: start;
`;

export const AllNewsButton = styled(LinkButton)`
    margin: 10px 0 0 0;
`;

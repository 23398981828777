import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { FC } from 'react';
import {
    ContentContainer,
    HighlightsButton,
    HighlightsContainer,
    HighlightsDescription,
    HighlightsHeadline,
    HighlightsImage,
    HighlightsTitle,
} from './OurHighlightsStyle';
import { urlForImage } from '../../../utils/urlForImage';
import { Link } from '../../../interfaces/link';
import { linkHelper } from '../../../utils/linkHelper';

export interface OurHighlightsProps {
    title: string | null;
    description: string | null;
    link: Link | null;
    imgSrc: string | null;
    imgAlt: string | null;
}

const OurHighlights: FC<OurHighlightsProps> = ({ title, description, link, imgSrc, imgAlt }) => {
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const preparedLink = linkHelper(link?.uri ? link.uri : '#', language);
    const preparedLinkTitle = link?.text && link?.text.length !== 0 ? link.text : t('readMore');

    return (
        <HighlightsContainer>
            <ContentContainer>
                <HighlightsHeadline>{t('ourHighlight')}</HighlightsHeadline>
                <HighlightsTitle to={preparedLink}>{title}</HighlightsTitle>
                <HighlightsDescription dangerouslySetInnerHTML={{ __html: description }}></HighlightsDescription>
                <HighlightsButton to={preparedLink}>{preparedLinkTitle}</HighlightsButton>
            </ContentContainer>
            <HighlightsImage src={urlForImage(imgSrc)} alt={imgAlt} />
        </HighlightsContainer>
    );
};

export default OurHighlights;

import styled from 'styled-components';
import { LinkButton, SectionHeadline, SectionTitleLink } from '../../../styles/Common';
import { deviceMax } from '../../../styles/MediaQuery';
import { greyLight } from '../../../styles/Variables';

export const HighlightsContainer = styled.div`
    background: ${greyLight};
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;

    @media ${deviceMax.tablet} {
        display: none;
    }
`;

export const ContentContainer = styled.div`
    padding: 15px;
`;

export const HighlightsHeadline = styled(SectionHeadline)``;

export const HighlightsTitle = styled(SectionTitleLink)``;

export const HighlightsDescription = styled.div`
    * {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        line-height: 20px;
    }
    padding-bottom: 0.4rem;
`;

export const HighlightsButton = styled(LinkButton)``;

export const HighlightsImage = styled.img`
    width: 100%;
`;

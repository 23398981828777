import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { FC } from 'react';
import { Button, ContentWrapper, OurSolutionsWrapper, SolutionsImage, TextWrapper, Title } from './OurSolutionsStyle';
import placeHolder from '../../static/images/placeholder.jpg';
import { urlForImage } from '../../utils/urlForImage';
import { linkHelper } from '../../utils/linkHelper';
import { SectionHeadline, SectionTitleLink } from '../../styles/Common';
import { Link } from '../../interfaces/link';

export interface OurSolutionsProps {
    headline: string;
    title: string | null;
    description: string | null;
    link: Link | null;
    imgSrc: string | null;
    imgAlt: string | null;
}

const OurSolutions: FC<OurSolutionsProps> = ({ headline, title, description, link, imgSrc, imgAlt }) => {
    const {
        t,
        i18n: { language },
    } = useTranslation();
    const preparedLink = linkHelper(link?.uri ? link.uri : '#', language);
    const preparedLinkTitle = link?.text && link?.text.length !== 0 ? link.text : t('readMore');

    return (
        <OurSolutionsWrapper>
            <SectionHeadline>{headline !== '' ? headline : t('ourSolutions')}</SectionHeadline>
            <SectionTitleLink to={preparedLink}>{title}</SectionTitleLink>
            <ContentWrapper>
                <SolutionsImage src={imgSrc ? urlForImage(imgSrc) : placeHolder} alt={imgAlt} />
                <TextWrapper>
                    <div dangerouslySetInnerHTML={{ __html: description }}></div>
                    <Button to={preparedLink}>{preparedLinkTitle}</Button>
                </TextWrapper>
            </ContentWrapper>
        </OurSolutionsWrapper>
    );
};

export default OurSolutions;

import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    :root {
        --black: #404040;
        --white: #fff;
    }
    html, body {
        margin: 0;
        padding: 0;
    }
`;

export default GlobalStyles;

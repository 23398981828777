import styled from 'styled-components';
import { ContainerColumnWithoutWidth, LinkButton } from '../../../styles/Common';
import selectDivider from '../../../static/images/landingPage/divider.png';
import selectArrow from '../../../static/images/landingPage/arrow_dropdown.png';
import { darkGreen, greyBorder, lightGray } from '../../../styles/Variables';
import { deviceMax } from '../../../styles/MediaQuery';

export const SectionContainer = styled(ContainerColumnWithoutWidth)`
    align-items: flex-start;
    border-right: 1px solid ${greyBorder};
    padding-right: 20px;
    flex: 1;

    @media ${deviceMax.tablet} {
        border-right: none;
    }
`;

export const CareersText = styled.p`
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 0;
    padding: 0;
    margin-bottom: 0.8rem;
    font-family: Arial, Helvetica, sans-serif;
`;

export const JobsButton = styled(LinkButton)`
    margin: 5px 0 15px 0;
`;

export const Select = styled.select`
    background: url(${selectDivider}) no-repeat 84% 43%, url(${selectArrow}) no-repeat 93% 45%,
        linear-gradient(180deg, #fafbfc 30%, #eff2f5 90%);
    border: 1px solid ${lightGray};
    cursor: pointer;
    padding-left: 0.8rem;
    margin: 0.5rem 0;
    width: 17rem;
    height: 2.5rem;
    color: ${darkGreen};
    border-radius: 5px;
    appearance: none;
    outline: none;
    font-size: 15px;

    option {
        color: ${darkGreen};
        padding-top: 2px;
    }

    @media ${deviceMax.tablet} {
        width: 75%;
        margin-top: 0;
    }
`;

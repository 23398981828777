import React, { FC } from 'react';
import { Container, RedirectionButton, SearchIcon, Title } from './ProductRedirectionStyle';

interface ProductRedirectionProps {
    link: string | null;
    linkLabel: string | null;
    title: string | null;
}

const ProductRedirection: FC<ProductRedirectionProps> = ({ link, linkLabel, title }) => (
    <Container>
        <SearchIcon />
        <Title>{title}</Title>
        <RedirectionButton to={link ? link : '#'}>{linkLabel}</RedirectionButton>
    </Container>
);

export default ProductRedirection;

import React, { FC } from 'react';
import EventsSection from './EventsSection/EventsSection';
import { Container } from './FrontpageNewsStyle';
import NewsSection from './NewsSection/NewsSection';
import { LinkFieldType, EventType, NewsType } from '../../interfaces/frontpage';

interface FrontpageNewsProps {
    news?: Array<NewsType>;
    events?: Array<EventType>;
    allNewsLink?: LinkFieldType;
    allEventsLink?: LinkFieldType;
    newsTitle?: string;
    eventsTitle?: string;
}

const FrontpageNews: FC<FrontpageNewsProps> = ({
    news,
    events,
    allNewsLink,
    allEventsLink,
    newsTitle,
    eventsTitle,
}) => (
    <Container>
        {news && news?.length > 0 && (
            <NewsSection news={news} allNewsLink={allNewsLink} newsTitle={newsTitle} border={!!events?.length} />
        )}
        {events && events?.length > 0 && (
            <EventsSection events={events} allEventsLink={allEventsLink} eventsTitle={eventsTitle} />
        )}
    </Container>
);

export default FrontpageNews;

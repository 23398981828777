import React, { FC } from 'react';
import { LinkTitle, ProductImage, ProductWrapper } from './OurProductStyle';
import { urlForImage } from '../../../utils/urlForImage';
import Link from '../../../interfaces/link';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { linkHelper } from '../../../utils/linkHelper';

export interface OurProductProps {
    title: string | null;
    imgSrc: string | null;
    imgAlt: string | null;
    link: Link | null;
}

const OurProduct: FC<OurProductProps> = ({ title, imgSrc, imgAlt, link }) => {
    const {
        i18n: { language },
    } = useTranslation();

    const preparedLink = linkHelper(link?.uri ? link.uri : '#', language);

    return (
        <ProductWrapper to={preparedLink}>
            <LinkTitle>{title}</LinkTitle>
            <ProductImage src={urlForImage(imgSrc)} alt={imgAlt} />
        </ProductWrapper>
    );
};

export default OurProduct;

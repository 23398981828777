import styled from 'styled-components';
import { ContainerColumnWithoutWidth, LinkButton, SectionHeadline } from '../../../styles/Common';
import { blue, citronBackground, greyLight, limaBackground, white } from '../../../styles/Variables';
import { BiSearch } from 'react-icons/bi';

export const Container = styled(ContainerColumnWithoutWidth)`
    background: ${greyLight};
    justify-content: center;
`;

export const SearchIcon = styled(BiSearch)`
    font-size: 1.5rem;
    border: 3px solid ${blue};
    border-radius: 50%;
    padding: 10px;
`;

export const Title = styled(SectionHeadline)`
    margin: 1rem 0;
`;

export const RedirectionButton = styled(LinkButton)`
    background: transparent linear-gradient(${limaBackground}, ${citronBackground}) repeat scroll 0 0;
    color: ${white};
    border: none;
    margin: 0;
`;

import styled from 'styled-components';
import { white, darkGreen, blue, blueDark, lightGray } from '../../styles/Variables';
import glpbg from '../../static/images/landingPage/glpbg.png';
import selectDivider from '../../static/images/landingPage/divider.png';
import selectArrow from '../../static/images/landingPage/arrow_dropdown.png';
import { FiArrowRight } from 'react-icons/fi';

export const Container = styled.div`
    padding: 0;
    text-align: center;
    width: 100%;
`;

export const Header = styled.header`
    height: 100px;
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    margin: 0 auto;
    width: 49vw;

    @media (max-width: 1023px) {
        width: 95%;
        margin-left: 0.5rem;
    }
`;

export const RedirectContainer = styled.div`
    background: linear-gradient(to right, ${blueDark}, ${blue});
    height: 360px;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    ::after {
        content: '';
        border-style: solid;
        border-width: 17px 100px 0 30px;
        border-color: ${white} ${white} transparent transparent;
        position: absolute;
        width: 60%;
        right: 0;
        top: -1px;

        @media (max-width: 1023px) {
            width: 20%;
        }
    }

    p {
        background: none;
        font-size: 2rem;
        font-weight: 400;
        font-family: 'Helvetica Neue LT W02_77 Bd Cn', Helvetica, Arial;
        color: ${white};
        z-index: 2;

        @media (max-width: 1023px) {
            font-size: 1.8rem;
        }
    }

    ::before {
        background-image: url(${glpbg});
        background-size: cover cover;
        background-position: center;
        background-repeat: no-repeat;
        content: '';
        display: inline-block;
        height: 360px;
        width: 100%;
        position: absolute;
    }
`;

export const FormContainer = styled.div`
    z-index: 2;
    background: none;

    form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    select {
        background: url(${selectDivider}) no-repeat 84% 43%, url(${selectArrow}) no-repeat 93% 45%,
            linear-gradient(180deg, #fafbfc 30%, #eff2f5 90%);
        border: 1px solid ${lightGray};
        cursor: pointer;
        padding-left: 0.8rem;
        width: 17rem;
        height: 2.5rem;
        color: ${darkGreen};
        border-radius: 5px;
        appearance: none;
        outline: none;

        option {
            color: ${darkGreen};
        }
    }

    button {
        font-size: 16px;
        width: 3em;
        height: 3em;
        border-radius: 3em;
        outline: none;
        border: none;
        background: ${darkGreen};
        margin-top: 1em;
        cursor: pointer;

        * {
            color: ${white};
        }
    }
`;

export const ArrowBtn = styled(FiArrowRight)`
    font-size: 1.5rem;
    margin: 0 auto;
    text-align: center;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 49vw;
    margin: 0 auto;
    text-align: left;

    @media (max-width: 1023px) {
        width: 95%;
        margin-left: 0.5rem;
    }

    div {
        width: 100%;

        h2 {
            color: ${blue};
            font-size: 1.25rem;
            font-weight: 700;
            letter-spacing: 0.025rem;
            margin-top: 3.5rem;
        }

        div {
            display: grid;
            grid-template-rows: 1fr 1fr 1fr;
            grid-auto-flow: column;

            @media (max-width: 1023px) {
                grid-auto-flow: row;
            }

            h3 {
                color: ${blue};
                font-size: 2.125rem;
                font-weight: 700;
                letter-spacing: 0.025rem;
                padding: 0;
                margin: 10px 0;
            }

            div {
                display: flex;
                flex-direction: column;

                span {
                    color: ${darkGreen};
                    cursor: pointer;
                }
            }
        }
    }
`;

export const Footer = styled.footer`
    background: linear-gradient(135deg, ${blueDark} 0%, ${blueDark} 40%, ${blue} 60%, ${blue} 100%);
    min-height: 15rem;
    margin-top: 9rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    @media (max-width: 1023px) {
        align-items: flex-start;
    }

    ::after {
        content: '';
        border-style: solid;
        border-width: 17px 30px 0 0;
        border-color: ${white} transparent transparent transparent;
        position: absolute;
        width: 70%;
        left: 0;
        top: -1px;
    }
`;

export const FooterContent = styled.div`
    color: ${white};
    display: flex;
    flex-direction: column;
    width: 49vw;
    text-align: left;
    margin-top: 3rem;

    @media (max-width: 1023px) {
        width: 100%;
    }

    h4 {
        color: ${white};
        font-size: 0.875rem;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        font-weight: 700;
        margin: 0;
        padding: 0;

        @media (max-width: 1023px) {
            padding-left: 0.5rem;
        }
    }

    h2 {
        text-align: right;
        color: ${white};

        @media (max-width: 1023px) {
            text-align: left;
            padding-left: 0.5rem;
        }

        a {
            color: ${white};
            text-decoration: none;
            font-size: 0.875rem;
            font-weight: 700;
            font-family: 'Helvetica Neue LT W02_57 Cond', Helvetica, Arial;
        }
    }
`;

export const ContactData = styled.div`
    color: ${white};
    display: flex;
    align-items: flex-end;
    gap: 3rem;

    @media (max-width: 1023px) {
        flex-direction: column;
        gap: 0;
        align-items: flex-start;
        padding-left: 0.5rem;
    }
`;

export const ContactDataItem = styled.div`
    p {
        color: ${white};
        font-size: 0.875rem;
        font-weight: 200;
        margin: 0;
        padding-top: 0.3rem;

        a {
            color: ${white};
            text-decoration: none;
            font-size: 0.875rem;
        }
    }
`;

export const FooterText = styled.div`
    background: ${blueDark};
    width: 100%;
    height: 3.5rem;
    display: flex;
    align-items: center;

    span {
        font-size: 1rem;
        font-family: 'Helvetica Neue LT W02_77 Bd Cn', Helvetica, Arial;
        color: ${white};
        text-transform: uppercase;
        font-weight: 100;
        width: 100%;
    }
`;

import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { FC } from 'react';
import { SectionHeadline, SectionTitle } from '../../styles/Common';
import OurHighlights from './OurHighlights/OurHighlights';
import OurProduct from './OurProduct/OurProduct';
import { Container, ProductsContainer, RedirectionHighlightsContainer } from './OurProductsStyle';
import ProductRedirection from './ProductRedirection/ProductRedirection';
import { linkHelper } from '../../utils/linkHelper';

interface OurProductsProps {
    headline: string;
    products: any;
    productRedirection: any;
    ourHighlights: any;
}

const OurProducts: FC<OurProductsProps> = ({ headline, products, productRedirection, ourHighlights }) => {
    const {
        t,
        i18n: { language },
    } = useTranslation();

    if (products && products.length > 0) {
        return (
            <Container>
                {Object.values(products[0]).some((item) => item !== null) && (
                    <>
                        <SectionHeadline>{headline !== '' ? headline : t('ourProducts')}</SectionHeadline>
                        <SectionTitle>{t('ourProductsTitle')}</SectionTitle>
                        <ProductsContainer>
                            {products
                                ?.filter(
                                    (item: any) =>
                                        item?.field_title && item?.field_image?.length > 0 && item?.field_link
                                )
                                .map((item: any, index: number) => (
                                    <OurProduct
                                        key={`${item.field_title}${index}`}
                                        title={item.field_title}
                                        imgSrc={item?.field_image[0]?.field_media_image}
                                        imgAlt={item?.field_image[0]?.alt}
                                        link={item?.field_link}
                                    />
                                ))}
                        </ProductsContainer>
                    </>
                )}
                <RedirectionHighlightsContainer>
                    {Object.values(productRedirection).some((item) => item !== null) && (
                        <ProductRedirection
                            link={linkHelper(productRedirection?.link ?? '#', language)}
                            linkLabel={productRedirection?.link_label}
                            title={productRedirection?.title}
                        />
                    )}
                    {Object.values(ourHighlights[0]).some((item) => item !== null) && (
                        <OurHighlights
                            title={ourHighlights[0]?.field_title?.length > 0 ? ourHighlights[0]?.field_title : ''}
                            description={
                                ourHighlights[0]?.field_description?.length > 0
                                    ? ourHighlights[0]?.field_description
                                    : ''
                            }
                            link={ourHighlights[0]?.field_link ?? null}
                            imgSrc={
                                ourHighlights[0]?.field_image?.length > 0
                                    ? ourHighlights[0]?.field_image[0]?.field_media_image
                                    : ''
                            }
                            imgAlt={
                                ourHighlights[0]?.field_image?.length > 0 ? ourHighlights[0]?.field_image[0]?.alt : ''
                            }
                        />
                    )}
                </RedirectionHighlightsContainer>
            </Container>
        );
    } else {
        return null;
    }
};

export default OurProducts;

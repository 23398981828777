import styled from 'styled-components';
import { deviceMax } from '../../styles/MediaQuery';
import { containerWidth } from '../../styles/Variables';

export const Container = styled.div`
    width: ${containerWidth};
    margin: 0 auto;
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 20px;
    margin-top: 2rem;

    @media ${deviceMax.laptop} {
        max-width: 920px;
        padding: 10px;
    }

    @media ${deviceMax.tablet} {
        width: calc(100% - 20px);
        grid-template-columns: 1fr;
    }
`;

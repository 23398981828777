import styled from 'styled-components';
import { ContainerColumnStart, LinkButton, SectionTitleLink } from '../../styles/Common';
import { deviceMax } from '../../styles/MediaQuery';
import { blue, darkGreen, white } from '../../styles/Variables';

export const OurSolutionsWrapper = styled(ContainerColumnStart)`
    margin: 0 auto;

    @media ${deviceMax.laptop} {
        padding: 0 10px;
    }
`;

export const Title = styled(SectionTitleLink)``;

export const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    background-color: ${blue};

    @media ${deviceMax.tablet} {
        flex-wrap: wrap;
        padding-bottom: 20px;
    }
`;

export const SolutionsImage = styled.img`
    width: 40%;
    object-fit: cover;

    @media ${deviceMax.tablet} {
        width: 100%;
    }
`;

export const TextWrapper = styled.div`
    width: 60%;
    margin: 20px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    * {
        color: ${white};
        font-size: 15px;
        font-family: Arial, Helvetica, sans-serif;
        overflow: hidden;
    }

    p {
        height: 105px;
        line-height: 1.5rem;
        margin: 0;
        padding-bottom: 20px;

        @media ${deviceMax.tablet} {
            height: auto;
        }
    }

    @media ${deviceMax.tablet} {
        width: 100%;
    }
`;

export const Button = styled(LinkButton)`
    width: intrinsic;
    width: max-content;
    color: ${darkGreen};
    font-size: 0.875rem;
    margin-top: 10px;
    margin-bottom: 0;
`;

import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { FC, ReactNode } from 'react';
import { SectionHeadline } from '../../../styles/Common';
import { createRangeDate } from '../../../utils/createRangeDate';
import { AllEventsButton, EventCard, EventDetails, EventName, SectionContainer } from './EventsSectionStyle';
import { linkHelper } from '../../../utils/linkHelper';
import { EventType, LinkFieldType } from '../../../interfaces/frontpage';

interface EventsSectionProps {
    events?: Array<EventType>;
    allEventsLink?: LinkFieldType;
    eventsTitle?: string;
}

const EventsSection: FC<EventsSectionProps> = ({ events, allEventsLink, eventsTitle }) => {
    const { t } = useTranslation();
    const { language } = useI18next();

    const displayEvents = (): ReactNode =>
        events
            ?.filter((item: any, index: number) => index < 3 && item)
            ?.map((item: any, index: number) => (
                <EventCard key={index} href={linkHelper(item?.field_event_link?.uri ?? '#', language)} target="_blank">
                    <EventDetails>
                        {item?.field_event_date.hasOwnProperty('start_date') &&
                            item?.field_event_date.hasOwnProperty('end_date') &&
                            createRangeDate(item.field_event_date?.start_date, item.field_event_date?.end_date)}
                    </EventDetails>
                    <EventDetails>{item?.field_location}</EventDetails>
                    <EventName>{item?.title}</EventName>
                </EventCard>
            ));

    return (
        <SectionContainer>
            <SectionHeadline>{eventsTitle && eventsTitle?.length > 0 ? eventsTitle : t('events')}</SectionHeadline>
            {displayEvents()}
            {allEventsLink?.uri && allEventsLink?.uri?.length > 0 && (
                <AllEventsButton to={`/${language}${allEventsLink.uri}`}>
                    {allEventsLink?.text && allEventsLink?.text?.length > 0 ? allEventsLink.text : t('allEvents')}
                </AllEventsButton>
            )}
        </SectionContainer>
    );
};

export default EventsSection;

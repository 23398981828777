import styled from 'styled-components';
import { darkGreyText, greyLightText } from '../../../styles/Variables';
import { ContainerColumnWithoutWidth, LinkButton } from '../../../styles/Common';

export const SectionContainer = styled(ContainerColumnWithoutWidth)`
    align-items: flex-start;
`;

export const EventCard = styled.a`
    text-decoration: none;

    * {
        margin: 0;
        margin-bottom: 3px;
        padding: 0;
        font-weight: 200;
        font-size: 0.875rem;
    }
`;

export const EventDetails = styled.p`
    color: ${greyLightText};
    font-family: Arial, Helvetica, sans-serif;
`;

export const EventName = styled.h4`
    color: ${darkGreyText};
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 10px;
`;

export const AllEventsButton = styled(LinkButton)`
    margin: 0.5rem 0;
`;

import { Link } from 'gatsby';
import styled from 'styled-components';
import { ContainerColumnWithoutWidth } from '../../../styles/Common';
import { deviceMax } from '../../../styles/MediaQuery';
import { darkGreen } from '../../../styles/Variables';

export const SliderWrapper = styled(ContainerColumnWithoutWidth)`
    align-items: flex-start;
    overflow: hidden;
    height: 100%;

    @media ${deviceMax.tablet} {
        width: 100%;
    }
`;

interface isActiveType {
    readonly isActive?: boolean;
    readonly position?: boolean;
}

export const ContentWrapper = styled.div<isActiveType>`
    display: flex;
    flex-wrap: wrap;
    padding-bottom: ${(props) => (props.isActive ? '15px' : '0')};
    transition: left 0.5s;
    position: relative;
    top: 0;
    left: ${(props) => {
        if (props.isActive) {
            return '0';
        } else if (props.position) {
            return '-1000px';
        } else {
            return '1000px';
        }
    }};

    @media ${deviceMax.tablet} {
        width: 100%;
        flex-direction: column;
    }
`;

export const SlideImage = styled.img<isActiveType>`
    display: ${(props) => (props.isActive ? 'block' : 'none')};
    flex: 1;
    height: 160px;
    width: 50%;
    object-fit: contain;
    margin-right: 15px;

    @media ${deviceMax.tablet} {
        margin-right: 0;
        height: unset;
        width: 100%;
        flex: unset;
    }
`;

export const TextWrapper = styled.div<isActiveType>`
    display: ${(props) => (props.isActive ? 'block' : 'none')};
    height: auto;
    width: calc(50% - 15px);
    * {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
    }

    @media ${deviceMax.tablet} {
        width: 100%;
    }
`;

export const Description = styled.div<isActiveType>`
    display: ${(props) => (props.isActive ? 'block' : 'none')};
    * {
        font-family: Arial, Helvetica, sans-serif;
    }
`;

export const PageLink = styled(Link)`
    font-family: Arial, Helvetica, sans-serif;
    color: ${darkGreen};
    text-decoration: none;
`;

export const ThumbnailsWrapper = styled.div`
    display: flex;
    width: 50%;
`;

interface ThumbnailImageInterface extends isActiveType {
    readonly image?: string;
}

export const ThumbnailImage = styled.div<ThumbnailImageInterface>`
    width: 70px;
    height: auto;
    min-height: 40px;
    min-width: 40px;
    aspect-ratio: 1 / 1;
    background: url(${(props) => props.image});
    background-size: cover;
    background-position: center;
    margin-right: 6px;
    ${(props) =>
        props.isActive &&
        `
        opacity: .3;
    `}

    &:last-child {
        margin-right: 0;
    }
`;
